  .courses__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .courses__container > div {
    background: var(--color-bg-2);
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
  }

  .courses__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-2);
    cursor: default;
}
  
  .courses__dat h3, .courses__other h3 {
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .courses__content {
    border-top: 1px solid var(--color-primary);
    padding-top: 1.5rem;
  }
  
  .course__details {
    display: flex;
    gap: 1rem;
    margin-bottom: 10px;
  }
  
  .course__details-icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin-top: 6px;
  }
  
  .course__details h4 {
    color: #333;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .course__details .text-light {
    font-size: 16px;
  }

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px) {
  .courses__container {
      grid-template-columns: 1fr;
  }

  .courses__container > div {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
  }

  .course__content {
      padding: 1rem;
  }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px) {
  .courses__container {
      gap: 1rem;
  }

  .courses__container > div {
      width: 100%;
      padding: 2rem 1rem;
  }
}
