.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-2);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column; /* Ensures the layout stacks vertically */
    justify-content: space-between; /* Ensures equal spacing between items */
    height: 100%; /* Makes all items the same height */
}

.portfolio__item:hover {
    border-color: var(--color-primary-2);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    flex-grow: 1; /* Ensures the title takes up available space and pushes the buttons down */
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem; /* Pushes the buttons to the bottom of the card */
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}
    
/* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}