.container.testimonials__container {
    width: 40%;
    padding-bottom: 4rem;
}

.client__avatar {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-2);
}

.testimonial {
    background: var(--color-bg-2);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}


/* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
}
    
/* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-small);
    }

    .client__review {
        width: var(--container-width-small);
    }

}
