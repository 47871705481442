header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========== CTA ========== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== HEADER SOCIALS ========== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========== PROFILE PICTURE ========== */
.ProfilePicture {
    width: 20rem;
    height: 28rem;
    position: absolute;
    left: calc(50% - 10rem);
    margin-top: 2rem;
    border-radius: 12rem 12rem 2rem 2rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.ProfilePicture::before {
    content: "";
    position: absolute;
    top: 1rem; /* This is the padding you want above the background */
    left: 0;
    right: 0;
    bottom: 0;
    /*background: linear-gradient(var(--color-primary), transparent);*/
    border-radius: 12rem 12rem 2rem 2rem;
    z-index: -1;
}

.ProfilePicture img {
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* ========== SCROLL DOWN ========== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

    /* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px) {
    header{
        height: 90vh;
    }

}

/* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px) {
    header {
        height: 110vh;
    }
    .header__socials, .scroll__down {
        display: none;
    }
    .ProfilePicture {
        width: 16.5rem;
        height: 22.5rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2rem;
        object-fit: contain;
    }
}

