.about__container {
    display: grid;
    grid-template-columns: 100%;
    gap: 15%;
    align-items: center;
    justify-content: center;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-2);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-2);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.9rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
}

    /* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
    @media screen and (max-width: 1024px) {
        .about__container {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .about__me {
            width: 50%;
            margin: 2rem auto 4rem;
        }

        .about__content p {
            margin: 1rem 0 1.5rem;
        }
    }
    
    /* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
    @media screen and (max-width: 600px) {
      .about__me {
        width: 65%;
        margin: 0 auto 3rem;
      }

      .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }

      .about__content {
        text-align: center;
      }

      .about__content p {
        margin: 1.5rem 0;
      }
    }
    