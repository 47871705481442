@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
/*
:root {
  --color-bg: #1f1f38;
  --color-bg-2: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-2: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-small: 90%;
  --container-width-medium: 86%;
  --container-width-large: 75%;

  --transition: all 400ms ease;
}
*/

/* Color 2
:root {
  --color-bg: #2f3e46;
  --color-bg-2: #354f52;
  --color-primary: #cad2c5;
  --color-primary-2: #84a98c;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-small: 90%;
  --container-width-medium: 86%;
  --container-width-large: 75%;

  --transition: all 400ms ease;
}
*/


:root {
  --color-bg: #884A39;
  --color-bg-2: #C38154;
  --color-primary: #F9E0BB;
  --color-primary-2: #FFC26F;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-small: 90%;
  --container-width-medium: 86%;
  --container-width-large: 75%;

  --transition: all 400ms ease;
}


/*
:root {
  --color-bg: #222831;
  --color-bg-2: #D7C0AE;
  --color-primary: #393E46;
  --color-primary-2: #967E76;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-small: 90%;
  --container-width-medium: 86%;
  --container-width-large: 75%;

  --transition: all 400ms ease;
}
*/

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(../src/assets/dark-brick-wall.png);
}

/* ========== GENERAL STYLES ========== */
.container {
  width: var(--container-width-large);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light)
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ==========*/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-medium);
  }

  section {
    margin-top: 6rem;
  }
}


/* ========== MEDIA QUERIES (SMALL DEVICES) ==========*/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-small);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}

/* Loading spinner used in index.html */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000000;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20% auto; /* centers the spinner vertically and horizontally */
  display: block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
